import React, { FC } from 'react';

import s from './ErrorContentLayout.scss';

import ErrorContent from './ErrorContent';
import ErrorContentLayoutFooter from './ErrorContentLayoutFooter';
import { TestIds } from './constants';

const ErrorContentLayout: FC<{}> = ({}) => {
  return (
    <div
      className={s.errorContentLayoutContainer}
      data-testid={TestIds.errorContentLayoutContainer}
    >
      <ErrorContent />
      <ErrorContentLayoutFooter />
    </div>
  );
};

export default ErrorContentLayout;
