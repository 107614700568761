import { ctaClick } from '@wix/bi-logger-any-marketing-lp/v2';
import { BI_Events, BI_Events_Error_Key } from '../../constants/biEvents';
import { factory } from '@wix/web-bi-logger';

type Logger = ReturnType<ReturnType<typeof factory>['logger']>;

interface errorPageDataType {
  title: string;
  description: string;
  footerLinkDescription: string;
  footerLink: string;
  code: string;
  nonBranded?: boolean;
  reportBiEvent: (biLogger: Logger) => void;
}

const reportEvent = ({
  biLogger,
  biEventsErrorKey,
}: {
  biLogger: Logger;
  biEventsErrorKey: BI_Events_Error_Key;
}) => {
  biLogger
    .report(
      ctaClick({
        ...BI_Events[biEventsErrorKey],
        pageUrl: window.location.href,
        targetUrl: errorMapping[biEventsErrorKey].footerLink,
      }),
    )
    .catch((e: Error) => console.log(e));
};

export const errorMapping: { [key: string]: errorPageDataType } = {
  '401': {
    title: 'studio.error.401.title',
    description: 'studio.error.401.description',
    code: '401',
    footerLinkDescription: 'studio.error.401.linkDescription',
    footerLink: 'studio.error.401.link',
    reportBiEvent: (biLogger) =>
      reportEvent({
        biLogger,
        biEventsErrorKey: '401',
      }),
  },
  '403': {
    title: 'studio.error.403.title',
    description: 'studio.error.403.description',
    code: '403',
    footerLinkDescription: 'studio.error.403.linkDescription',
    footerLink: 'studio.error.403.link',
    reportBiEvent: (biLogger) =>
      reportEvent({
        biLogger,
        biEventsErrorKey: '403',
      }),
  },
  '404': {
    title: 'studio.error.404.title',
    description: 'studio.error.404.description',
    code: '404',
    footerLinkDescription: 'studio.error.404.linkDescription',
    footerLink: 'studio.error.404.link',
    reportBiEvent: (biLogger) =>
      reportEvent({
        biLogger,
        biEventsErrorKey: '404',
      }),
  },

  '404-NotBranded': {
    title: 'studio.error.404.title',
    description: 'studio.error.404.description',
    code: '404',
    footerLinkDescription: 'studio.error.404.linkDescription',
    footerLink: 'studio.error.404.link',
    nonBranded: true,
    reportBiEvent: (biLogger) =>
      reportEvent({
        biLogger,
        biEventsErrorKey: '404',
      }),
  },
  '410': {
    title: 'studio.error.410.title',
    description: 'studio.error.410.description',
    code: '410',
    footerLinkDescription: 'studio.error.410.linkDescription',
    footerLink: 'studio.error.410.link',
    reportBiEvent: (biLogger) =>
      reportEvent({
        biLogger,
        biEventsErrorKey: '410',
      }),
  },

  '500': {
    title: 'studio.error.500.title',
    description: 'studio.error.500.description',
    code: '500',
    footerLinkDescription: 'studio.error.500.linkDescription',
    // statusPage ?
    footerLink: 'studio.error.500.link',
    reportBiEvent: (biLogger) =>
      reportEvent({
        biLogger,
        biEventsErrorKey: '500',
      }),
  },

  '503': {
    title: 'studio.error.503.title',
    description: 'studio.error.503.description',
    code: '503',
    footerLinkDescription: 'studio.error.503.linkDescription',
    // statuspage ?
    footerLink: 'studio.error.503.link',
    reportBiEvent: (biLogger) =>
      reportEvent({
        biLogger,
        biEventsErrorKey: '503',
      }),
  },
  default: {
    title: 'studio.error-title',
    code: '',
    description: 'studio.error.default.description',
    footerLinkDescription: 'studio.error.default.linkDescription',
    footerLink: 'studio.error.default.link',
    reportBiEvent: () => {},
  },
};
