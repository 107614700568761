import { ctaClickParams } from '@wix/bi-logger-any-marketing-lp/v2/types';

export type BI_Events_Error_Key = '401' | '403' | '404' | '410' | '500' | '503';

type BIEventsType = Record<BI_Events_Error_Key, ctaClickParams>;

export const BI_Events: BIEventsType = {
  '401': {
    buttonName: 'Log in  and try again',
    pageName: 'Studio 401 Error page',
  },
  '403': {
    buttonName: 'Go to the homepage',
    pageName: 'Studio 403 Error page',
  },
  '404': {
    buttonName: 'Go to the homepage',
    pageName: 'Studio 404 Error page',
  },
  '410': {
    buttonName: 'Go to the homepage',
    pageName: 'Studio 410 Error page',
  },
  '500': {
    buttonName: 'Check our status page for more information',
    pageName: 'Studio 500 Error page',
  },
  '503': {
    buttonName: 'Check our status page for more information',
    pageName: 'Studio 503 Error page',
  },
};
