import React from 'react';

import s from './ErrorContentLayoutFooter.scss';
import { useTranslation } from 'react-i18next';
import { useErrorData } from '../../../../hooks/use-error-data';
import { useBiLogger } from '../../../../hooks/use-bi-logger';
import { errorMapping } from '../../errorsMapping';

const ErrorContentLayoutFooter = () => {
  const { t } = useTranslation();
  const errorData = useErrorData();
  const biLogger = useBiLogger();
  const errorCode = errorData?.errorCode || 'default';
  const errorContent = errorMapping[errorCode].footerLinkDescription;
  const errorLink = errorMapping[errorCode].footerLink;

  return (
    <div className={s.errorContentFooterContainer}>
      <h3 className={s.footerDescription}>
        <a
          href={t(errorLink)}
          className={s.link}
          onClick={() => errorMapping[errorCode].reportBiEvent(biLogger)}
        >
          {' '}
          {t(errorContent) + ' →'}
        </a>
      </h3>
    </div>
  );
};

export default ErrorContentLayoutFooter;
