import React, { useRef } from 'react';
import layout from '../../assets/images/layout.png';
import global from '../../assets/images/global.png';
import s from './Header.scss';
import { Logo } from './Logo';
import { useTranslation } from 'react-i18next';
import { TestIds } from './constants';

const Header = () => {
  const navRef = useRef<HTMLElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const logoRef = useRef<HTMLAnchorElement | null>(null);
  const { t } = useTranslation();
  const menuItems = [
    {
      text: 'studio.header.designText',
      link: 'studio.header.designLink',
    },
    {
      text: 'studio.header.developmentText',
      link: 'studio.header.developmentLink',
    },
    {
      text: 'studio.header.businessText',
      link: 'studio.header.businessLink',
    },
    {
      text: 'studio.header.enterpriseText',
      link: 'studio.header.enterpriseLink',
    },
  ];

  const showNavBar = () => {
    if (navRef.current) {
      navRef.current.classList.toggle(s.responsive_nav);
    }
    if (overlayRef.current) {
      overlayRef.current.classList.toggle(s.show_overlay);
    }
    if (logoRef.current) {
      logoRef.current.focus();
    }
  };

  return (
    <header className={s.header}>
      <a
        href={t('studio.header.logoLink')}
        data-testid={TestIds.logo}
        ref={logoRef}
        aria-label="Wix Studio"
      >
        <Logo />
      </a>
      <nav ref={navRef} className={s.nav}>
        {menuItems.map((item) => (
          <a key={item.text} href={t(item.link)} className={s.a}>
            {t(item.text)}
          </a>
        ))}
        <a
          href={t('studio.header.loginLink')}
          className={s.logIn + ' ' + s.logInMobile}
          data-testid={TestIds.loginButtonMobile}
        >
          {t('studio.header.loginText')}
        </a>
      </nav>
      <div className={s.rightSideContainer}>
        <div className={s.globalContainer}>
          <img src={global} alt="global img" className={s.globalImg}></img>
          <a
            href={t('studio.header.loginLink')}
            className={s.logIn + ' ' + s.logInDesktop}
            data-testid={TestIds.loginButtonDesktop}
          >
            {t('studio.header.loginText')}
          </a>
        </div>
        <a
          href={t('studio.header.startCreatingLink')}
          className={s.startCreating}
        >
          <span>{t('studio.header.startCreatingText')}</span>
        </a>
      </div>
      <button
        className={s.navBtn}
        onClick={showNavBar}
        data-testid="nav-button"
      >
        <img
          src={layout}
          className={s.layoutImg}
          alt={t('studio.header.menu')}
        />
      </button>
      <div className={s.overlay} ref={overlayRef} onClick={showNavBar} />
    </header>
  );
};

export default Header;
