import React, { FC } from "react";

import BackgroundErrorImg from "../Background";
import ErrorContentLayout from "./ErrorContentLayout";
import { TestIds } from './constants';

import s from "./Content.scss";

const Content: FC = () => {
  return (
    <div className={s.contentContainer} data-testid={TestIds.contentContainer}>
      <ErrorContentLayout />
      <BackgroundErrorImg />
    </div>
  );
};

export default Content;
