import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { create as createFedopsLogger } from "@wix/fedops-logger";
import i18n from "./i18n";
import App from "./components/App";
import { ErrorDataProvider } from "./hooks/use-error-data";

const locale = window.__LOCALE__;
const errorData = window.__ERROR_DATA__;

const fedopsLogger = createFedopsLogger("studio-error-pages-statics");

// Move the following `appLoaded()` call to the point where your app has fully loaded.
// See https://github.com/wix-private/fed-infra/blob/master/fedops/fedops-logger/README.md
fedopsLogger.appLoaded();

const domNode = document.getElementById("root")!;
const root = createRoot(domNode);
root.render(
  <I18nextProvider i18n={i18n(locale)}>
    <ErrorDataProvider errorData={errorData}>
      <App useSuspense={false} />
    </ErrorDataProvider>
  </I18nextProvider>
);
