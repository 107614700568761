import React from 'react';
import WixStudioLogo from '../../assets/images/wixStudioLogo.svg';

import s from './Header.scss';

export const Logo = () => {
  return (
    <div className={s.logo}>
      <div className={s.studioLogo}>
        <WixStudioLogo />
      </div>
    </div>
  );
};
