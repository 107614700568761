import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import s from "./App.scss";
import Content from "../Content/Content";
import Header from "../Header/Header";

export interface AppProps extends WithTranslation { }

class App extends React.Component<AppProps> {
  render() {
    const {t} = this.props;

    return (
      <div className={s.root}>
        <Header />
        <Content />
      </div>
    );
  }
}

export default withTranslation()(App);
