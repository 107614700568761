import React, { FC } from "react";

import s from "./ErrorContent.scss";
import { useErrorData } from "../../../../hooks/use-error-data";
import { errorMapping } from "../../errorsMapping";
import { useTranslation } from "react-i18next";
import { TestIds } from "../constants";

const ErrorContent: FC<{}> = ({ }) => {
  const { t } = useTranslation();
  const errorData = useErrorData();
  if (!errorData) return null;

  const { errorCode, requestId } = errorData;
  const { code, description, title } = errorMapping[errorCode];
  return (
    <div className={s.errorContentContainer}>
      <div className={s.contentContainer}>
        <div className={s.errorDescriptionContainer}>
          <h2 className={s.errorContentTitle}>{t(title)}</h2>
          <h1 className={s.errorContentCodeTitle}>{code}</h1>
        </div>
        <div className={s.errorContentDescription}>
          <p className={s.errorContentDescriptionText}>{t(description)}</p>
          {requestId && errorCode !== '404' ? (
            <p
              className={s.errorRequestIDText}
              data-testid={TestIds.errorContentRequestId}
            >
              {t('studio.header.requestId')}: {requestId}
            </p>
          ) : (
            <p className={s.errorRequestIDText}> </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorContent;
